// import "./css/app.scss";
import About from "./components/about/About";
import { Contact } from "./components/contact/Contact";
import Cursor from "./components/cursor/Cursor";
import HeroBanner from "./components/herobanner/HeroBanner";
import Navbar from "./components/navbar/Navbar";
import Parallax from "./components/parallax/Parallax";
import Projects from "./components/projects/Projects";
import SkillsTab from "./components/skills/SkillsTab";





function App() {
  return (
    <div className="pRelative">
      <Cursor />
      <section id="Homepage">
        <Navbar/>
        <HeroBanner />
      </section>
      <section >
        <Parallax  type="about"/>
      </section>
      <section id="About">
        <SkillsTab className="skillstab" />
      </section >
      <section >
        <Parallax  type="projects"/>
      </section>
        <Projects />
      <section id="Contact">
        <Contact />
      </section>
    </div>
  );
}

export default App;
