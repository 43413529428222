import React, { useEffect, useState } from "react";
import "./navbar.scss";
import Sidebar from "../sidebar/Sidebar";
import Logo from "../../assets/logo/logo-ini.png";
import { FaGithubSquare } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import { ImFilesEmpty } from "react-icons/im";
import { motion } from "framer-motion";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showLabel, setShowLabel] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleHover = (value) => {
    setShowLabel(value);
  };

  const handleMouseLeave = () => {
    setShowLabel('');
  };

  return (
    <div className={`navbar ${scrolled ? "scrolled" : ""}`}>
      {/* slider */}
      <Sidebar />
      <div className="wrapper">
        <motion.span
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="logo-wrapper"
        >
          <img className="logo" src={Logo} alt="Logo" />
        </motion.span>

        <motion.div className="social">
          {showLabel && <span className="iconLabel">{showLabel}</span>}
          <motion.a
            onMouseEnter={() => handleHover("Resume")}
            onMouseLeave={handleMouseLeave}
            whileHover={{ scale: 1.4 }}
            transition={{ duration: 0.3 }}
            href="./TulashiResume.pdf"
            target="_blank"
            >
            <ImFilesEmpty className="social-icon resume" />
          </motion.a>
          <motion.a
            onMouseEnter={() => handleHover("LinkedIn")}
            onMouseLeave={handleMouseLeave}
            whileHover={{ scale: 1.4 }}
            transition={{ duration: 0.3 }}
            href="https://linkedin.com/in/bajgait/"
            target="_blank"
          >
            <SiLinkedin className="social-icon linkedin" />
          </motion.a>
          <motion.a
            onMouseEnter={() => handleHover("GitHub")}
            onMouseLeave={handleMouseLeave}
            whileHover={{ scale: 1.4 }}
            transition={{ duration: 0.3 }}
            href="https://github.com/Tbajgai1"
            target="_blank"
          >
            <FaGithubSquare className="social-icon github" />
          </motion.a>
        </motion.div>
      </div>
    </div>
  );
};

export default Navbar;
