import Links from "./links/Links"
import ToggleButton from "./toggleButtons/ToggleButton"

import "./sidebar.scss";

import { useState } from "react";
import { motion } from "framer-motion";

const variants = {
  open: {
    clipPath: "circle(1200px at 50px 50px)",
    transition: {
      type:"spring",
      stiffness: 20
    }

  },
  closed: {
    clipPath: "circle(30px at 50px 50px)",
    transition: {
      delay:0.5,
      type: "spring",
      stiffness:400,
      damping: 40
    }
  }
}

const Sidebar = () => {
  const [open, setOpen] = useState(false);

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const closeSideBar = () => {
    setOpen(false);
  }

  return (
    <motion.div className={`sidebar ${open ? 'open' : 'close'}`} animate = {open ? "open" : "closed"}>
        <motion.div className="bg" variants={variants}>
          <Links closeSideBar={closeSideBar}/>
          <ToggleButton setOpen = {toggleSidebar} />
        </motion.div>
    </motion.div>
  )
}

export default Sidebar