import './contact.scss';
import { motion } from 'framer-motion';
import { useInView } from 'framer-motion';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useState } from 'react';

const variants = {
    initial: {
        x:-100,
        opacity:0
    },
    animate: {
        x:0,
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: 0.1
        }
    }
};


export const Contact = () => {
    
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const formRef = useRef();
    const ref = useRef();
    const isInView = useInView(ref, {margin: "-100px"});

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_pwar52q', 'template_5hjgpcw', formRef.current, 'xHBJarIexKed2M8Gp')
            .then((result) => {
                setSuccess(true);
                formRef.current.reset();
            }, (error) => {
                setError(true);
            });
    };

  return (
        <motion.div 
            name="contact"
            ref={ref}
            className='contact' 
            variants={variants} 
            initial="initial" 
            whileInView="animate"
        >
            <div className="textContainer" variants={variants}>
                <motion.h2 variants={variants}>I would Love to hear from you</motion.h2>
                <motion.div className="item" variants={variants}>
                    <h3>E-Mail</h3>
                    <span>Bajgait@gmail.com</span>
                </motion.div>
                <motion.div className="item" variants={variants}>
                    <h3>Phone</h3>
                    <span>825-461-5290</span>
                </motion.div>
            </div>
            <div className="formContainer">
                <motion.div 
                    className="phoneSvg" 
                    initial={{opacity:1}} 
                    whileInView={{opacity:0}} 
                    transition={{delay: 3, duration:1}}
                >    
                    <svg viewBox="0 0 32 32"  width="450px" height="450px">
                        <motion.path 
                            strokeWidth={0.2}
                            stroke= "orange"
                            fill= "none"
                            initial={{pathLength:0}}
                            animate={isInView && {pathLength: 1}}
                            transition={{duration: 3}}
                            d="M16.014 32c-.468 0-.939-.02-1.413-.06C6.808 31.277.545 24.886.035 17.072A16.033 16.033 0 0 1 4.68 4.694 16.042 16.042 0 0 1 17.051.034c7.685.491 14.074 6.625 14.862 14.269a15.997 15.997 0 0 1-.867 7.154.999.999 0 1 1-1.88-.682c.725-2 .98-4.109.758-6.267-.691-6.684-6.28-12.049-13.002-12.478A14.04 14.04 0 0 0 6.094 6.107a14.033 14.033 0 0 0-4.063 10.835c.446 6.834 5.923 12.426 12.74 13.005 3.156.271 6.217-.491 8.844-2.197a.936.936 0 0 0 .423-.702 1.018 1.018 0 0 0-.295-.83l-.193-.193a.999.999 0 1 1 1.41-1.418l.193.192c.65.646.969 1.541.874 2.454a2.939 2.939 0 0 1-1.323 2.173A15.836 15.836 0 0 1 16.014 32z"
                        />
                        <motion.path 
                            strokeWidth={0.2}
                            fill= "none"
                            stroke= "Orange"
                            initial={{pathLength:0}}
                            animate={isInView && {pathLength: 1}}
                            transition={{duration: 4}}
                            d="M19.232 27.016c-2.812 0-5.045-1.344-8.475-4.773-4.316-4.316-5.329-6.739-4.516-10.803.794-3.969 3.936-5.581 4.069-5.648a.999.999 0 0 1 1.154.188l4.95 4.95a.999.999 0 0 1 0 1.414l-2.566 2.566c.117.301.514 1.038 1.859 2.383s2.083 1.743 2.383 1.859l2.566-2.566a.999.999 0 0 1 1.414 0l4.95 4.95a1 1 0 0 1 .188 1.154c-.067.133-1.679 3.275-5.648 4.069-.835.167-1.601.257-2.328.257zM10.619 7.963a6.396 6.396 0 0 0-2.417 3.87c-.618 3.091-.111 4.915 3.969 8.996 4.081 4.082 5.905 4.587 8.996 3.969a6.397 6.397 0 0 0 3.87-2.417l-3.673-3.673-2.121 2.121c-.144.144-.697.585-1.73.242-.865-.288-1.918-1.061-3.22-2.363-1.302-1.302-2.075-2.355-2.363-3.22-.345-1.036.098-1.586.242-1.73l2.121-2.121-3.674-3.674zM18.253 19.2h.01-.01z" 
                        />
                    </svg>

                </motion.div>
                <motion.form 
                    ref={formRef}
                    onSubmit={sendEmail}
                    initial={{opacity:0}} 
                    whileInView={{opacity:1}} 
                    transition={{delay: 3.5, duration:2}}
                >
                    <motion.input
                        type="text" 
                        placeholder= "Name" 
                        name="name"
                        required 
                    />
                    <motion.input 
                        type="email" 
                        placeholder= "Email" 
                        name="email"
                        required 
                    />
                    <motion.textarea 
                        placeholder='Message' 
                        rows="8" 
                        name="message"
                        required
                    />
                    <button>Submit</button>
                    {error && "Error sending Email. Please try again."}
                    {success && "Message sent. Thank you very much!"}
                </motion.form>
            </div>
        </motion.div>

  )
}
