import "./projects.scss";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import ImageSlider from "./ImageSlider";
import items from './ProjectsData'



const Single = ({item}) => {

    const ref = useRef();
    const { scrollYProgress } = useScroll({
        target: ref,
    });

    const y = useTransform(scrollYProgress, [0, 1], [-400, 400]);

    return (
        <section >
            <div className="container">
                <div className="wrapper" ref={ref}>
                    <div className="image-container">
                        <ImageSlider item={item} />
                    </div>
                    <motion.div className="textContainer" style={{y}}>
                        <h3>{item.title}</h3>
                        <div className= "icon-wrapper">
                            {item.icons.map((icon, index) => (
                                <img key={index} src={icon} alt="icon" />
                            ))}
                        </div>
                        
                        <p>{item.description}</p> 

                        <div className="projectButtons">
                            <a href={item.linkDemo} target="_blank">
                                <button>Demo</button>
                            </a>
                            <a href={item.linkCode} target="_blank">
                                <button>Code</button>
                            </a>
                        </div>
                        
                    </motion.div>
                </div>
            </div>

        </section>
    )
}

function Projects() {
    const ref = useRef();

    const { scrollYProgress } = useScroll({
        target: ref, 
        offset:["end end", "start start"]
    });

    const scaleX = useTransform(scrollYProgress, [0.001, 1], [0, 1], {
        stiffness: 100,
        damping: 30
    });


    return (
        <div className="projects" ref={ref} id="Projects">
            <div className="progress-bar">
                <h2>Featured Projects</h2>
                <motion.div style={{ scaleX }} className="progressBar" ></motion.div>
            </div>
            {items.map(item =>(
                <Single item = {item} key={item.id}/>
            ))}
        </div>
    )
}

export default Projects