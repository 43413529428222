import banner from "../../assets/other/banner.png";
import scroll from "../../assets/other/scroll.png";
import './hero.scss';

import { motion } from "framer-motion";

const textVariants = {
    initial: {
        x: -500,
        opacity: 0
    },
    animate: {
        x:0,
        opacity: 1,
        transition: {
            duration:1,
            staggerChildren: 0.1
        }
    },
    scrollButton: {
        opacity: 0,
        y: 10,
        transition: {
            duration:2,
            repeat:Infinity
        }

    }
};

const sliderVariants = {
    initial: {
        x: 0
    },
    animate: {
        x: "-220%",
        transition: {
            repeat: Infinity,
            repeatType: "mirror",
            duration:30,
        }
    }
};


function HeroBanner() {
  return (
    <div className='hero'>
        <div className="hero-wrapper">
            <motion.div className="intro-container" variants={textVariants} initial="initial" animate= "animate">
                <motion.h2 variants={textVariants}>Tulashi.B</motion.h2>
                <motion.h4 variants={textVariants}>
                    Web 
                    <span>Developer</span> &<br/>
                     UI 
                    <span>Designer</span> 
                </motion.h4>
                <motion.div className="home-buttons" variants={textVariants}>
                    <a href="#Projects">
                        <motion.button className="projects-btn" variants={textVariants}>View Projects</motion.button>
                    </a>
                    <a href="#Contact">
                        <motion.button className="contact-btn" variants={textVariants}>Contact Me</motion.button>
                    </a>
                </motion.div>
                <motion.img  variants={textVariants} animate="scrollButton" src={scroll} alt="scroll bar" />
            </motion.div>
            <motion.div className="slidingTextContainer" variants={sliderVariants} initial="initial" animate="animate" >
                HTML CSS JavaScript Node.js React Next.js PHP SQL MySQL 
            </motion.div>
            <div className="imageContainer">
                <img src={banner} alt="banner image" />
            </div>
        </div>
    </div>
  )
}

export default HeroBanner