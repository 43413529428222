import LinearProgress from '@mui/material/LinearProgress';

//import icons
import MySql from '../../assets/backend/mysql.png';
import Php from '../../assets/backend/php.png';
import Sql from '../../assets/backend/sql.png';
import Node from '../../assets/backend/node.png';


//import jsx component
import SkillsCard from './SkillsCard';






const BackEnd = () => {
  return (
    
        <div className='flex'>

          {/* PHP  */}
              <SkillsCard title="PHP" alt="PHP icon" src= {Php} description= {<LinearProgress variant="determinate" value={75} />}/>

          {/* MySql  */}

              <SkillsCard title="MySql" alt="MySql icon" src= {MySql} description= {<LinearProgress variant="determinate" value={80} />}/>

          {/* SQL  */}

              <SkillsCard title="Sql" alt="Sql icon" src= {Sql} description= {<LinearProgress variant="determinate" value={80} />}/>
          
          {/* Node */}
          <SkillsCard title="Node" alt="Node icon" src= {Node} description= {<LinearProgress variant="determinate" value={70} />}/>
        </div>
  )
}

export default BackEnd;