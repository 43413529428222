import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { motion } from "framer-motion";
import { useRef } from "react";

import FrontEnd from './FrontEnd';
import BackEnd from './BackEnd';
import OtherSkills from './OtherSkills';

import 'bootstrap/dist/css/bootstrap.min.css';
import './skills.scss';


const variants = {
  initial: {
      x: -500,
      opacity: 0
  },
  animate: {
      x:0,
      opacity:1,
      y:0,
      transition: {
          duration: 1,
          staggerChildren: 0.1
      }
  }
};


function SkillsTab() {
  const ref = useRef();

  return (
    
        <motion.div 
            className="about" 
            variants={variants} 
            initial="initial" 
            ref={ref} 
            animate={"animate"}
        >
            <motion.div className="textContainer"  variants={variants}>
                <p>Web Developer with a passion for learning <br /> and embracing new technologies</p>
                <hr />
              
            </motion.div>

            <Tabs
              defaultActiveKey="frontend"
              id="skillstab"
              className="tab-container"
              fill
            >
              <Tab eventKey="frontend" title="Front-End">
                <FrontEnd />
              </Tab>
              <Tab eventKey="backend" title="Back-End">
                <BackEnd />
              </Tab>
              <Tab eventKey="other" title="Other">
                <OtherSkills />
              </Tab>
            </Tabs>
        
        </motion.div>
  );
}

export default SkillsTab;