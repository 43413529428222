import Carousel from 'react-bootstrap/Carousel';

function ImageSlider({ item }) {
        return (
            <Carousel className='imageSlider'>
                {item.img.map((image, index) => (
                <Carousel.Item key={index} className="carousel-wrapper">
                    <img className="carouselImg" src={image} alt={`Image ${index}`} />
                </Carousel.Item>
                ))}
            </Carousel>
        );
}

export default ImageSlider;