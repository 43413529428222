import LinearProgress from '@mui/material/LinearProgress';

//import icons
import Joomla from '../../assets/other/joomla.png';
import Wordpress from '../../assets/other/wordpress.png';
import Photoshop from '../../assets/other/photoshop.png';
import AdobeXD from '../../assets/other/adobexd.png';
import Git from '../../assets/other/git.png';

//import jsx component
import SkillsCard from './SkillsCard';



const OtherSkills = () => {
  return (
        <div className='flex'>

          {/* Joomla  */}
              <SkillsCard title="Joomla" alt="Joomla icon" src= {Joomla} description= {<LinearProgress variant="determinate" value={70} />}/>

          {/* CSS  */}

              <SkillsCard title="WordPress" alt="WordPress icon" src= {Wordpress} description= {<LinearProgress variant="determinate" value={85} />}/>

          {/* Photoshop  */}

          <SkillsCard title="Photoshop" alt="Photoshop icon" src= {Photoshop} description= {<LinearProgress variant="determinate" value={80} />}/>

          {/* Adobe XD  */}

          <SkillsCard title="Adobe XD" alt="Adobe XD icon" src= {AdobeXD} description= {<LinearProgress variant="determinate" value={75} />}/>
             
          {/* Git  */}

          <SkillsCard title="Git" alt="Git icon" src= {Git} description= {<LinearProgress variant="determinate" value={80} />}/>
        </div>
  )
}

export default OtherSkills;