
//import png skills
import HTML from '../../assets/frontend/html.png';
import CSS from '../../assets/frontend/css.png';
import JavaScript from '../../assets/frontend/js.png';
import ReactImg from '../../assets/frontend/react.png';
import Tailwind from '../../assets/frontend/tailwind.png';
import Bootstrap from '../../assets/frontend/bootstrap.png';
import Nextjs from '../../assets/frontend/nextjs.png';
import jQuery from '../../assets/frontend/jquery.png';


//import jsx component
import SkillsCard from './SkillsCard';

import LinearProgress from '@mui/material/LinearProgress';


const FrontEnd = () => {
  return (
      <div className="skills-container-wrapper">
        <div className='flex'>

          {/* HTML  */}
              <SkillsCard title="HTML" alt="HTML icon" src= {HTML} description= {<LinearProgress variant="determinate" value={95} />} />
                
          {/* CSS  */}

              <SkillsCard title="CSS" alt="CSS icon" src= {CSS} description= {<LinearProgress variant="determinate" value={95} />}/>

          {/* JS  */}

              <SkillsCard title="JavaScript" alt="Java Script icon" src= {JavaScript} description= {<LinearProgress variant="determinate" value={75} />}/>

          {/* REACT  */}

              <SkillsCard title="React" alt="React icon" src= {ReactImg} description= {<LinearProgress variant="determinate" value={60} />}/>

          {/* NEXT.JS */}

              <SkillsCard title="React" alt="React icon" src= {Nextjs} description= {<LinearProgress variant="determinate" value={75} />}/>

          {/* Tailwind */}

              <SkillsCard title="Tailwind" alt="TailWind icon" src= {Tailwind} description= {<LinearProgress variant="determinate" value={95} />}/>

          {/* Bootstrap */}

              <SkillsCard title="Bootstrap" alt="Bootstrap icon" src= {Bootstrap} description= {<LinearProgress variant="determinate" value={95} />}/>
          
          {/* jQuery */}

          <SkillsCard title="jQuery" alt="jQuery icon" src= {jQuery} description= {<LinearProgress variant="determinate" value={50} />}/>

        </div>
      </div>
  )
}

export default FrontEnd;