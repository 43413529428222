import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';


export default function SkillsCard(props) {

  return (
      <Card sx={{ display: 'flex', flexWrap:'wrap', justifyContent:'center', alignItems:'center', ':hover': {boxShadow: 20}}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width:'70%' }}>
          <CardContent sx={{ flex: '1 0 auto'}}>
            <Typography component="div" variant="h5">
              {props.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div" className='mt-3'>
              {props.description}
            </Typography>
          </CardContent>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: 100, p: 1 }}
          image= {props.src}
          alt= {props.alt}
          
        />
      </Card>
  );
}