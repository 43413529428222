import { motion } from "framer-motion";

import "./parallax.scss";



const Parallax = ({type}) => {
  return (
    <div 
        className="parallax"
        style={{
            background:
                type ==="about"
                    ? "linear-gradient(180deg, #060f30, #1b2a5f)"
                    : "linear-gradient(180deg, #5e6da5, #060f30)"
        }}
    >
       
        <motion.h2>{type==="about" ? "About Me" : "My Projects"}</motion.h2>
        <motion.div className={type === "about" ? "picture1" : "picture2"}>
            
        </motion.div>
       
    </div>
  )
}

export default Parallax;
