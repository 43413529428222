import { motion } from "framer-motion";


const variants = {
    open: {
        transition:{
            staggerChildren:  0.1
        }
    },
    closed: {
            staggerChildren: 0.5,
            staggerChildren: -1
    }
};

const itemVariants = {
    open: {
        y: 0,
        opacity: 1
    },
    closed: {
       y: 50,
       opacity: 0
    }
};

const Links = ({closeSideBar}) => {

    const handleClick = () => {
        closeSideBar();
    }

    const items = [
        "Homepage",
        "About",
        "Projects",
        "Contact"
    ]

    return (
        <motion.div className="links" variants={variants}>
            {
                items.map((item) => (
                    <motion.a 
                        href={`#${item}`} 
                        key={item} 
                        variants={itemVariants}
                        whileHover = {{scale:1.2}}
                        whileTap ={{scale: 0.95}}
                        onClick={handleClick}
                    >
                        {item}
                    </motion.a>
                ))
            }
        </motion.div>
    )
}

export default Links