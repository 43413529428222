
// Images import
// Everything coding
import coding from '../../assets/projects/everything-coding-1.webp';
import coding1 from '../../assets/projects/everything-coding-2.webp';
import coding2 from '../../assets/projects/everything-coding-3.webp';
import coding3 from '../../assets/projects/everything-coding-4.webp';
import coding4 from '../../assets/projects/everything-coding-5.webp';




// image gallary
import gallery from '../../assets/projects/img-gallery-not-logged-in.webp';
import gallery1 from '../../assets/projects/img-gallary-login.webp';
import gallery3 from '../../assets/projects/image-gallary-rating.webp';
import gallery4 from '../../assets/projects/image-gallary-loggedin.webp';
import gallery5 from '../../assets/projects/image-gallary-register.webp';


// quiz app
import quizapp1 from '../../assets/projects/quiz-app-home.webp';
import quizapp2 from '../../assets/projects/quiz-app-questions.webp';
import quizapp3 from '../../assets/projects/quiz-app-right-answer.webp';
import quizapp4 from '../../assets/projects/quiz-app-wrong-answer.webp';
import quizapp5 from '../../assets/projects/quiz-app-score.webp';

//Countdown
import countdown from '../../assets/projects/countDown.webp';
import countdown1 from '../../assets/projects/customCountdown.webp';



// Icons import 
//front-end
import html from '../../assets/frontend/html.png';
import css from '../../assets/frontend/css.png';
import jquery from '../../assets/frontend/jquery.png';
import js from '../../assets/frontend/js.png';
import react from '../../assets/frontend/react.png';
import bootstrap from '../../assets/frontend/bootstrap.png';

//backend
import php from '../../assets/backend/php.png';
import node from '../../assets/backend/node.png';
import sql from '../../assets/backend/sql.png';
import mysql from '../../assets/backend/mysql.png';




const items = [

    {
      id: 1,
      category:"Full-Stack",
      title: "Everything Coding Blog",
      img: [coding, coding1, coding2, coding3, coding4],
      icons: [ html, css, react, sql, node ],
      description: 
          "'Everything Coding' is a full-stack web application designed to facilitate the sharing and discussion of programming-related content. Built using HTML, CSS, React, SQL, Node.js, and Express, this platform empowers users to register, log in, and contribute their own posts. With features like user authentication using JWT tokens, liking posts, and commenting, it provides a dynamic and interactive space for developers to engage with each other's ideas and insights.",
      features: 
          "User Authentication, Content Management, Interaction and Engagement, Responsive Design, Backend Functionality",
      linkDemo: "https://everythingcoding.vercel.app/",
      linkCode: "https://github.com/Tbajgai1/everything-coding-v2"
    },
    {
      id: 2,
      category:"Full-Stack",
      title: "Image Gallery",
      img: [gallery, gallery1, gallery3, gallery4, gallery5],
      icons: [html, css, jquery, php, sql, mysql, bootstrap ],
      description: 
          "Image Gallery is a vibrant web platform designed to serve as a central hub for creative individuals. This PHP-powered website offers users the ability to effortlessly create accounts, log in securely, and showcase their creative works, including images and artworks. As a community-driven space, it fosters an environment where users can upload their creations, receive constructive feedback and ratings, and reciprocate by engaging with and rating others' images. Leveraging a blend of PHP, SQL, MySQL, jQuery, HTML, CSS, and Bootstrap, this platform provides a seamless and immersive experience for artists and creative enthusiasts to exhibit their talents, engage in meaningful interactions, and cultivate a dynamic artistic community.",

      linkDemo: "https://bajgait.com/projects/community-gallery/",
      linkCode: "https://github.com/Tbajgai1/projects/tree/main/community-gallery"
    },
    {
      id: 3,
      category:"Front-End",
      title: "Quiz App",
      img: [quizapp1, quizapp2,quizapp3,quizapp4,quizapp5],
      icons: [js, html, css],
      description: 
          "The Quiz App is a web application designed to provide users with an interactive and engaging quiz-taking experience. Users can select their preferred quiz parameters, such as the number of questions, category, and difficulty level, and then proceed to take a quiz on their chosen topics. The app fetches quiz questions from an external API and dynamically displays them on the webpage. Users can answer questions, receive immediate feedback on their responses, and view their final score at the conclusion of the quiz.",

      linkDemo: "https://bajgait.com/projects/quiz-app/",
      linkCode: "https://github.com/Tbajgai1/projects/tree/main/quiz-app"

      },
    {
      id: 4,
      category: "Front-End",
      title: "Countdown app",
      img: [countdown, countdown1],
      icons: [html, css, js],
      description: 
            "The Countdown App is a dynamic web application crafted using HTML, CSS, and JavaScript to create engaging countdown experiences. This app offers a dual functionality: it automatically displays the countdown to the next New Year and seamlessly transitions to the countdown for the upcoming year when the clock strikes zero.",
      linkDemo: "https://bajgait.com/projects/countdown/",
      linkCode: "https://github.com/Tbajgai1/projects/tree/main/countdown"
    }
  ];
    
export default items;